import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Power } from 'react-feather';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useDispatch } from "react-redux";
import { logoutAccount } from "../container/login/redux/login_slice";
import { RouterRoutes } from "../App";
import { Worker } from '@react-pdf-viewer/core';
import Footer from "./footer";
import ICPLBEService from "../services/icpl_backend";
import Swal from "sweetalert2";

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.pathname === "/") navigate(RouterRoutes.icpl);
        // eslint-disable-next-line
    }, []);

    const handleLogout = async () => {
        await Swal.fire({
            icon: 'info',
            title: 'Logging out',
            text: 'Please wait...',
            didOpen: async () => {
                Swal.showLoading();
                const userCtrl = ICPLBEService.getUserService();
                try {
                    await userCtrl.revokeToken();
                }
                catch (_) { }
                Swal.close();
            }
        })
        dispatch(logoutAccount());
        navigate(RouterRoutes.login);
    }

    return (
        <>
            <nav className="nav">
                <div className="logo">
                    <img src="assets/island_concrete.png" alt="Island Concrete" />
                </div>
                <div className="actions">
                    <TooltipComponent position="TopLeft" content="Logout" target="#logout" openDelay={800}>
                        <Power id="logout" color="red" style={{ cursor: "pointer" }} onClick={handleLogout} />
                    </TooltipComponent>
                </div>
            </nav>
            <main style={{padding: '53px 20px 70px 20px'}}>
                <Worker workerUrl="assets/js/pdf.worker.min.js">
                    <Outlet />
                </Worker>
            </main>
            <Footer />
        </>
    )
}