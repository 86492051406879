import { useEffect } from "react";

export const SignatureType = {
    signOnGlass: 'signOnGlass',
    unsigned: 'unsigned',
    ongoing: 'ongoing'
}

export default function SignatureTemplate({ data, signatureLoader, onClick }) {
    const proofStatus = (data.ProofStatus ?? '').toLowerCase();
    const signatureId = `signature-template-${data.TicketNum}`;

    useEffect(() => {
        if (signatureLoader && proofStatus === 'signed' && data.DeliveryKey) {
            setLoading(true);
            const signature = signatureLoader.getSignature(data.deliveryKey);

            if (!signature) {
                signatureLoader.addQueue(data.DeliveryKey, result => {
                    if (result.isSuccess) {
                        console.log(result);
                        const signatureData = result.data;
                        setSignatures(signatureData?.Signatures);
                    }
                    else {
                        const signatureNotFoundElem = document.getElementById(`${signatureId}-not-found`);
                        if (signatureNotFoundElem) signatureNotFoundElem.style.display = 'block';
                    }

                    setLoading(false);
                });
            }
            else {
                const signatureData = signature.data;
                if (signatureData?.Signatures) setSignatures(signatureData?.Signatures);
                else {
                    const signatureNotFoundElem = document.getElementById(`${signatureId}-not-found`);
                    if (signatureNotFoundElem) signatureNotFoundElem.style.display = 'block';
                }
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }

        return () => signatureLoader.removeToQueue(data?.DeliveryKey);
        // eslint-disable-next-line
    }, []);

    const setSignature = (id, signature) => {
        if (signature) {
            const parentElem = document.getElementById(`${signatureId}-${id}`);
            const elem = document.querySelector(`#${signatureId}-${id} > img`);
            if (elem && parentElem) {
                console.log(elem);
                elem.src = `data:image/jpeg;base64,${signature}`;
                parentElem.style.display = 'block';
            }
        }
    }

    const setSignatures = (signatures) => {
        console.log(signatures);
        setSignature('receiver', signatures?.Receiver);
        setSignature('consultant', signatures?.Consultant);
        setSignature('chemical-applicator', signatures?.ChemicalApplicator);
    }

    const setLoading = (isLoading) => {
        const signatureElem = document.getElementById(`${signatureId}-signature`);
        const loadingElem = document.getElementById(`${signatureId}-loader`);
        if (loadingElem) loadingElem.style.display = isLoading ? 'inline-block' : 'none';
        if (isLoading && signatureElem) signatureElem.style.display = 'none';
        else if (signatureElem) signatureElem.style.display = 'inline-block';
    }

    return (
        <div className='image'>
            {proofStatus === 'ongoing' && <span className="sign-on-glass-label" onClick={() => onClick({
                type: SignatureType.ongoing,
                data: data
            })} style={{color: 'green'}}>ONGOING</span>}
            {proofStatus === 'unsigned' && <span className="sign-on-glass-label" onClick={() => onClick({
                type: SignatureType.unsigned,
                data: data
            })} style={{color: 'red'}}>UNSIGNED JOB</span>}
            {proofStatus === 'signed' && <div id={`${signatureId}-signature`} style={{display: 'none'}}>
                <span className="sign-on-glass-label" onClick={() => onClick({
                    type: SignatureType.signOnGlass,
                    data: data
                })}>SIGN ON GLASS</span>
                <div id={`${signatureId}-receiver`} style={{display: 'none'}}>
                    <br/>
                    <img alt="Receiver Signature" height={80} width={100}/>
                </div>
                <div id={`${signatureId}-consultant`} style={{display: 'none'}}>
                    <br/>
                    <span style={{textWrap: 'wrap'}}>Consultant Signature</span>
                    <br/>
                    <img alt="Consultant Signature" height={80} width={100}/>
                </div>
                <div id={`${signatureId}-chemical-applicator`} style={{display: 'none'}}>
                    <br/>
                    <span style={{textWrap: 'wrap'}}>Chemical Applicator Signature</span>
                    <br/>
                    <img alt="Chemical Applicator Signature" height={80} width={100}/>
                </div>
                <div id={`${signatureId}-not-found`} style={{display: 'none'}}>
                    <p style={{textWrap: 'wrap', color: 'red'}}>Signature not found</p>
                </div>
            </div>}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <div id={`${signatureId}-loader`} className="loader"></div>
            </div>
            {/*<div id={`signatureTemplateSpinner${data.TicketNum}`} style={isFetching ? { position: 'relative', height: '40px' } : {}}></div>*/}
        </div>
    );
}