import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import { RouterRoutes } from "../../App";
import Loader from "../Loader";
import AthenaSettings, {AthenaSettingsKeys} from "../../utils/athena_settings";
import {logoutAccount, setAccount} from "../../container/login/redux/login_slice";
import moment from "moment";
import Swal from "sweetalert2";
import ICPLBEService from "../../services/icpl_backend";

export default function AuthGuard(props) {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        showLoader: true,
        navigateTo: RouterRoutes.login
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(async () => {
            let authInfo = { ...auth };
            if (!auth.isAuthenticated) {
                const settings = new AthenaSettings();
                //authenticate if rememberMe is false but the date diff is <= 0
                const rememberMe = settings.value.rememberMe.value;
                let account = settings.value.account;
                if (account) {

                    const diff = moment(account.expiration).diff(moment(), 'day');
                    if (rememberMe && diff <= 15) {
                        try {
                            const userCtrl = ICPLBEService.getUserService();
                            const tokenReq = await userCtrl.refreshToken();
                            const data = tokenReq.data;
                            settings.setItem(AthenaSettingsKeys.account, data);
                            account =  data;
                        }
                        catch(e)  {
                            if (e?.response?.status === 401) {
                                dispatch(logoutAccount());
                                navigate(RouterRoutes.login);
                            }
                        }
                    }
                    else if (diff < 5) {
                        dispatch(logoutAccount());
                        navigate(RouterRoutes.login);
                    }

                    dispatch(setAccount(account));
                    authInfo.isAuthenticated = true;
                    authInfo.showLoader = false;
                }
                else authInfo.showLoader = false;
            }
            else {
                authInfo.isAuthenticated = true;
                authInfo.showLoader = false;
            }

            setAuth(authInfo)
        }, 500);
        // eslint-disable-next-line
    }, []);

    if (auth.showLoader) return <Loader />
    else if (auth.isAuthenticated) return props.element;
    else return <Navigate to={auth.navigateTo} replace />
}