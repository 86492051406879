import { useState, memo, useEffect } from "react";
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import DropdownFiltering from "../../../utils/syncfusion/dropdown_filtering";
import { Grid, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import { icplSelector, icplStore, icplUseFormDefaultVal, setCustomers, setDestinations, setICPLFilter, setLoading, setOrders, setOrigins, setVehicles } from "../redux/icpl_slice";
import Loader from "../../../components/Loader";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import ICPLFilterUtils from "../utils/filter";
import moment from "moment";
import ICPLBEService from "../../../services/icpl_backend";
import Swal from "sweetalert2";
import {logoutAccount} from "../../login/redux/login_slice";
import {RouterRoutes} from "../../../App";
import {useNavigate} from "react-router-dom";

const Statuses = [
    {
        text: 'Any',
        value: "Any"
    },
    {
        text: 'Ongoing',
        value: 'Ongoing'
    },
    {
        text: 'Unsigned',
        value: 'Unsigned'
    },
    {
        text: 'Signed',
        value: 'Signed'
    }
]

const ICPLFilterForm = forwardRef((_, ref) => {
    const store = useStore();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { customers, vehicles, destinations, origins, triggerSearch } = useSelector(icplSelector(['customers', 'vehicles', 'destinations', 'origins', 'triggerSearch']), shallowEqual);
    const [isFetching, setFetching] = useState(false);

    const isRequestInit = useRef(false);
    const projectSiteRef = useRef();
    const vehicleDropdownRef = useRef();
    const customerDropdownRef = useRef();
    const batchingPlantDropdownRef = useRef();
    const requestStatus = useRef({
        vehicle: {
            loading: false,
            completed: false
        },
        customer: {
            loading: false,
            completed: false
        },
        origins: {
            loading: false,
            completed: false
        },
        destinations: {
            loading: false,
            completed: false
        },
    });
    const submitRef = useRef();
    const abortCtrlRef = useRef(new AbortController());

    const { t } = useTranslation();
    const { control, handleSubmit, setValue, getValues, reset } = useForm({
        defaultValues: icplUseFormDefaultVal
    });

    useImperativeHandle(ref, () => {
        return {
            getFilter() {
                return getValues();
            },
            setFilter(data) {
                reset(data);
            }
        }
    });

    useEffect(() => {
        return () => {
            const reqCtrl = abortCtrlRef.current;
            if (reqCtrl && !reqCtrl.signal.aborted) {
                reqCtrl.abort();
            }
        }
        // eslint-disable-next-line
    }, []);

    const triggerSearchPrev = useRef(1);
    useEffect(() => {
        //When accordion is closed/open the useRef values is refreshed to default
        if ((triggerSearch - triggerSearchPrev.current) > 2) triggerSearchPrev.current = triggerSearch;

        //prevent from click from triggering multiple times
        if (triggerSearch > triggerSearchPrev.current && !isRequestInit.current) {
            triggerSearchPrev.current = triggerSearch;
            submitRef.current.click();
        }
        // eslint-disable-next-line
    }, [triggerSearch]);

    // useEffect(() => {
    //     customerOnChange();
    //     // eslint-disable-next-line
    // }, [watchCustomerAccNo]);

    // const customerOnChange = async () => {
    //     try {
    //         setValue('ShipToCode', '');
    //         if (watchCustomerAccNo && destinations.filter(f => f.OwnerCustomerCode === watchCustomerAccNo).length === 0) {
    //             if (projectSiteRef.current) projectSiteRef.current.showSpinner();
    //             const zoneService = KHSchedulerService.getZoneService();
    //             const projectSitesReq = await zoneService.getDestination(watchCustomerAccNo);
    //             const projectSites = projectSitesReq.data;
    //             dispatch(setDestinations(destinations.concat(projectSites)));
    //             if (projectSiteRef.current) projectSiteRef.current.hideSpinner();
    //         }
    //     } catch (error) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Unable to fetch destinations.',
    //             text: 'Try again'
    //         });
    //         setValue('CustomerAccNo', '');
    //         setValue('CustomerName', '');
    //     }
    // }

    const deliveries = useRef([]);
    const onSubmit = async payload => {
        setFetching(true);
        if (!isRequestInit.current) {
            isRequestInit.current = true;

            setTimeout(async () => {
                dispatch(setLoading(true));
                dispatch(setOrders([]));

                deliveries.current = [];

                payload.page = 1;
                payload.page_length = 50;

                //to prevent from rerendering page when textSearch value is changed
                const { textSearch } = icplStore(store, ['textSearch']);

                if (textSearch) payload.TextSearch = textSearch;
                if (payload.DeliveryStatus) payload.DeliveryStatus = payload.DeliveryStatus.toUpperCase();

                Object.keys(payload).forEach(key => {
                    if (!payload[key] || payload[key] === 'ANY') delete payload[key];
                    else if (['EndDateTime', 'StartDateTime'].includes(key)) {
                        payload[key] = moment(payload[key]).utc().toISOString();
                    }
                });

                let isFailed = false;
                let canFetch = true;

                dispatch(setICPLFilter(payload));
                if (abortCtrlRef.current) {
                    abortCtrlRef.current.abort();
                }

                abortCtrlRef.current = new AbortController();
                const signal = abortCtrlRef.current.signal;

                do {
                    try {

                        const eDoDelivery = ICPLBEService.getEDeliveryOrderService();
                        const eDoRes = await eDoDelivery.getAll(payload, signal);

                        if (eDoRes.status === 200) {
                            const eDos = eDoRes.data;
                            // eslint-disable-next-line
                            deliveries.current = deliveries.current.concat(eDos);
                            //implemented on BE
                            //Do not show Cancelled jobs (ReasonCode is not empty)
                            //Do not show Stamp data (Stamp data = DeliveryID > 0 AND DeliveryKey is empty)
                            // eDos = eDos.filter(f => !f.ReasonCode && !(f.DeliveryID > 0 && !f.DeliveryKey));
                            canFetch = eDos?.length === payload.page_length;

                            if ((payload.page === 1 && !canFetch) || (payload.page === 2)) {
                                if (payload.page === 1 && !canFetch) dispatch(setLoading(false));

                                setFetching(false);
                                if(!canFetch){
                                    dispatch(setLoading());
                                }                                
                                setTimeout(() => {
                                    dispatch(setOrders(deliveries.current));
                                }, 500);
                            }
                            else if (!canFetch) {
                                dispatch(setLoading(false));
                                dispatch(setOrders(deliveries.current));
                            }

                            payload.page += 1;
                        }
                    } catch (error) {
                        console.error(error);
                        if (!signal.aborted) {
                            if (error?.response?.status === 401) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Session Expired',
                                    text: 'Please, login again to continue.'
                                }).then(() => {
                                    dispatch(logoutAccount());
                                    navigate(RouterRoutes.login);
                                });
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Failed to fetch delivery order',
                                    text: 'Try again.'
                                });
                            }
                        }
                        isFailed = true;
                        dispatch(setLoading(false));
                        setFetching(false);
                    }
                } while (!isFailed && canFetch);

                isRequestInit.current = false;
            }, 1000);
        }
        else {
            setTimeout(() => {
                dispatch(setOrders([]));
                isRequestInit.current = false;
                abortCtrlRef.current?.abort();
                onSubmit(payload);
            }, 500);
        }
    }

    const vehicleIsOpened = () => {
        const status = requestStatus.current.vehicle;
        if (status.loading) {
            setTimeout(() => vehicleDropdownRef.current.hidePopup(), 5);
            return;
        }

        if (!status.completed && !status.loading) {
            setTimeout(async () => {
                requestStatus.current.vehicle.loading = true;
                vehicleDropdownRef.current.hidePopup();
                vehicleDropdownRef.current.showSpinner();

                try {
                    const vehiclePayload = {
                        page: 1,
                        page_length: 100
                    }

                    let canFetch = false;
                    let vehicles = [];

                    const vehicleService = ICPLBEService.getVehicleService();

                    do {
                        const vehiclesReq = await vehicleService.getVehicles(vehiclePayload);
                        const vehicleRes = vehiclesReq.data;
                        const vehicleData = vehicleRes.data;
                        canFetch = vehicleRes.next_page_url !== null;
                        vehicles = vehicles.concat(vehicleData);
                        if (canFetch) vehiclePayload.page += 1;
                    } while (canFetch);

                    dispatch(setVehicles(vehicles));
                    requestStatus.current.vehicle = {
                        completed: true,
                        loading: false
                    }
                    vehicleDropdownRef.current.showPopup();
                    vehicleDropdownRef.current.hideSpinner();
                } catch (error) {
                    vehicleDropdownRef.current.hideSpinner();
                    requestStatus.current.vehicle = {
                        completed: false,
                        loading: false
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to fetch vehicles',
                        text: 'Try again'
                    });
                }
            }, 5);
        }
    }

    const customerIsOpened = () => {
        const status = requestStatus.current.customer;
        if (status.loading) {
            setTimeout(() => customerDropdownRef.current.hidePopup(), 5);
            return;
        }

        if (!status.completed && !status.loading) {
            setTimeout(async () => {
                requestStatus.current.customer.loading = true;
                customerDropdownRef.current.hidePopup();
                customerDropdownRef.current.showSpinner();

                try {
                    const customerPayload = {
                        page: 1,
                        page_length: 100
                    }
                    let canFetch = false;
                    let customers = [];

                    const customerService = ICPLBEService.getCustomerService();

                    do {
                        const customerReq = await customerService.getCustomers(customerPayload);
                        const customerRes = customerReq.data;
                        const vehicleData = customerRes.data;
                        canFetch = customerRes.next_page_url !== null;
                        customers = customers.concat(vehicleData);
                        if (canFetch) customerPayload.page += 1;
                    } while (canFetch);

                    dispatch(setCustomers(customers));
                    requestStatus.current.customer = {
                        completed: true,
                        loading: false
                    }
                    customerDropdownRef.current.showPopup();
                    customerDropdownRef.current.hideSpinner();
                } catch (error) {
                    customerDropdownRef.current.hideSpinner();
                    requestStatus.current.customer = {
                        completed: false,
                        loading: false
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to fetch customers',
                        text: 'Try again'
                    });
                }
            }, 5);
        }
    }

    const batchingPlantIsOpened = () => {
        const status = requestStatus.current.origins;
        if (status.loading) {
            setTimeout(() => batchingPlantDropdownRef.current.hidePopup(), 5);
            return;
        }

        if (!status.completed && !status.loading) {
            setTimeout(async () => {
                requestStatus.current.origins.loading = true;
                batchingPlantDropdownRef.current.hidePopup();
                batchingPlantDropdownRef.current.showSpinner();

                try {
                    const batchingPlantPayload = {
                        page: 1,
                        page_length: 100
                    }
                    let canFetch = false;
                    let batchingPlants = [];

                    const batchingPlantService = ICPLBEService.getBatchingPlantService();

                    do {
                        const batchingPlantReq = await batchingPlantService.getBatchingPlants(batchingPlantPayload);
                        const batchingPlantRes = batchingPlantReq.data;
                        const batchingPlantData = batchingPlantRes.data;
                        canFetch = batchingPlantRes.next_page_url !== null;
                        batchingPlants = batchingPlants.concat(batchingPlantData);
                        if (canFetch) batchingPlantPayload.page += 1;
                    } while (canFetch);

                    dispatch(setOrigins(batchingPlants));
                    requestStatus.current.origins = {
                        completed: true,
                        loading: false
                    }
                    batchingPlantDropdownRef.current.showPopup();
                    batchingPlantDropdownRef.current.hideSpinner();
                } catch (error) {
                    batchingPlantDropdownRef.current.hideSpinner();
                    requestStatus.current.origins = {
                        completed: false,
                        loading: false
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to fetch batching plants',
                        text: 'Try again'
                    });
                }
            }, 5);
        }
    }

    const projectSiteIsOpened = () => {
        const status = requestStatus.current.destinations;
        if (status.loading) {
            setTimeout(() => projectSiteRef.current.hidePopup(), 5);
            return;
        }

        if (!status.completed && !status.loading) {
            setTimeout(async () => {
                requestStatus.current.destinations.loading = true;
                projectSiteRef.current.hidePopup();
                projectSiteRef.current.showSpinner();

                try {
                    const projectSitePayload = {
                        page: 1,
                        page_length: 100
                    }
                    let canFetch = false;
                    let projectSites = [];

                    const projectSiteService = ICPLBEService.getProjectSiteService()

                    do {
                        const projectSiteReq = await projectSiteService.getProjectSites(projectSitePayload);
                        const projectSiteRes = projectSiteReq.data;
                        const projectSiteData = projectSiteRes.data;
                        canFetch = projectSiteRes.next_page_url !== null;
                        projectSites = projectSites.concat(projectSiteData);
                        if (canFetch) projectSitePayload.page += 1;
                    } while (canFetch);

                    dispatch(setDestinations(projectSites));
                    requestStatus.current.destinations = {
                        completed: true,
                        loading: false
                    }
                    projectSiteRef.current.showPopup();
                    projectSiteRef.current.hideSpinner();
                } catch (error) {
                    projectSiteRef.current.hideSpinner();
                    requestStatus.current.destinations = {
                        completed: false,
                        loading: false
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to fetch project site',
                        text: 'Try again'
                    });
                }
            }, 5);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="StartDateTime"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={true}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('icpl.startLabel')}
                            disabled={isFetching}
                            readonly={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="EndDateTime"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={true}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('icpl.endLabel')}
                            disabled={isFetching}
                            readonly={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="DeliveryStatus"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={Statuses}
                            autofill={true}
                            // onChange={(val) => valueOnChange('Vehicle', val)}
                            readonly={isFetching}
                            showClearButton={true}
                            fields={{
                                text: 'text',
                                value: 'value'
                            }}
                            placeholder={t('icpl.deliveryStatLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="Vehicle"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            ref={ref => {
                                field.ref = ref;
                                vehicleDropdownRef.current = ref;
                            }}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={vehicles}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            allowFiltering={true}
                            open={vehicleIsOpened}
                            filtering={(args) => DropdownFiltering(args, vehicles, 'Name')}
                            fields={{
                                text: 'Name',
                                value: 'Name'
                            }}
                            placeholder={t('icpl.vehiclesLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="CustomerName"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            ref={ref => {
                                field.ref = ref;
                                customerDropdownRef.current = ref;
                            }}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={customers}
                            autofill={true}
                            showClearButton={true}
                            readonly={isFetching}
                            onChange={args => {
                                const value = args.value;
                                // const customerCode = customers.filter(f => f.CustomerName === value)[0].CustomerCode;
                                setValue('CustomerName', value);
                                // setValue('CustomerCode', customerCode);
                            }}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, customers, 'CustomerName')}
                            open={customerIsOpened}
                            fields={{
                                text: 'CustomerName',
                                value: 'CustomerName'
                            }}
                            placeholder={t('icpl.customerNameLabel')} />
                    )} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="CustomerCode"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={customers}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            onChange={args => {
                                const value = args.value;
                                const customerName = customers.filter(f => f.CustomerCode === value)[0].CustomerName;
                                setValue('CustomerName', customerName);
                                setValue('CustomerCode', value);
                            }}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, customers, 'CustomerCode')}
                            fields={{
                                text: 'CustomerCode',
                                value: 'CustomerCode'
                            }}
                            placeholder={t('icpl.customerCodeLabel')} />
                    )} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="Origin"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            ref={ref => {
                                field.ref = ref;
                                batchingPlantDropdownRef.current = ref;
                            }}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={origins}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, origins, 'Name')}
                            open={batchingPlantIsOpened}
                            fields={{
                                text: 'Name',
                                value: 'Name'
                            }}
                            placeholder={t('icpl.batchingPlantLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="Destination"
                    control={control}
                    render={({ field }) => {
                        const dataSource = destinations.filter(f => f.TypeID === 'PROJECT_SITE');
                        return (
                            <DropDownListComponent
                                {...field}
                                ref={ref => {
                                    field.ref = ref;
                                    projectSiteRef.current = ref;
                                }}
                                cssClass="e-outline"
                                floatLabelType='Always'
                                dataSource={dataSource}
                                autofill={true}
                                allowFiltering={true}
                                showClearButton={true}
                                filtering={(args) => DropdownFiltering(args, destinations, 'Name')}
                                open={projectSiteIsOpened}
                                fields={{
                                    text: 'Name',
                                    value: 'Name'
                                }}
                                placeholder={t('icpl.projectSiteLabel')} />
                        );
                    }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <div className="center-content">
                    <Stack spacing={2} direction={'row'}>
                        <ButtonComponent
                            cssClass="e-warning"
                            readonly={isFetching}
                            onClick={() => reset(icplUseFormDefaultVal)}
                            style={{
                                width: '130px'
                            }}>
                            {t('icpl.clearBtnTitle')}
                        </ButtonComponent>
                        <ProgressButtonComponent
                            ref={submitRef}
                            cssClass="e-primary"
                            content={t('icpl.filterBtnTitle')}
                            duration={!isFetching ? 600 : 100000}
                            spinSettings={{
                                position: 'Center'
                            }}
                            animationSettings={{
                                effect: 'ZoomOut'
                            }}
                            style={{
                                width: '130px'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                        </ProgressButtonComponent>
                    </Stack>
                </div>
            </Grid>
        </Grid>
    );
})

const ICPLFilter = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const timeout = useRef();
    const filterData = useRef(icplUseFormDefaultVal);
    const filterFormRef = useRef();

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };

    const handlExpanded = event => {
        if (timeout.current) clearTimeout(timeout.current);

        const isExpanded = event.isExpanded;
        if (!isExpanded && filterFormRef.current) {
            filterData.current = filterFormRef.current.getFilter();
        }
        else if (filterFormRef.current) {
            timeout.current = setTimeout(() => {
                filterFormRef.current.setFilter(filterData.current);
                timeout.current = null;
            }, 500);
        }
        setIsExpanded(isExpanded);
    }

    const getHeaderContent = () => !isExpanded ? new ICPLFilterUtils(filterData.current).getFilterHeader() : 'Filter';

    return (
        <AccordionComponent expanded={handlExpanded} animation={animation} expandMode='Single'>
            <AccordionItemsDirective>
                <Loader />
                <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header={getHeaderContent()} content={() => (
                    <div className="e-card" id="basic_card" style={{ width: '100%' }}>
                        <div className="e-card-content">
                            <ICPLFilterForm ref={filterFormRef} />
                        </div>
                    </div>
                )}></AccordionItemDirective>
            </AccordionItemsDirective>
        </AccordionComponent>
    );
}

export default memo(ICPLFilter);