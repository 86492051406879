import FindPlusIntegration from "../../../services/findplus_integration";

export default function SignatureLoader(signatureCallback) {
    const signatures = [];
    let queue = [];
    let isInit = false;
    let timeout = null;

    this.getSignature = (deliveryKey) => {
        return signatures.find(f => f.deliveryKey === deliveryKey);
    }

    this.cancelQueue = () => {
        queue = [];
    }

    this.removeToQueue = (deliveryKey) => {
        const queueItem = queue.find(f => f.deliveryKey === deliveryKey);
        if (queueItem) {
            const index = queue.indexOf(queueItem);
            queue.splice(index, 1);
        }
    }

    this.addQueue = (deliveryKey, callback) => {
        if (deliveryKey) {
            const notExistQue = queue.filter(f => f.deliveryKey === deliveryKey).length === 0;
            const signature = signatures.find(f => f.deliveryKey === deliveryKey);

            if (signature) {
                callback(signature);
            }
            else {
                if (!notExistQue) this.removeToQueue(deliveryKey);

                queue.push({
                    deliveryKey,
                    callback
                });

                if (!timeout) {
                    timeout = setTimeout(() => {
                        if (queue.length > 0 && !isInit) this.init();
                        timeout = null;
                    }, 200);
                }
            }
        }
    }

    this.init = async () => {
        const proofService = FindPlusIntegration.getProofService();

        isInit = true;
        do {
            const item = queue.shift();
            console.log(item.deliveryKey);
            if (item) {
                //Check if signatures is already fetched
                const signature = signatures.filter(f => f.deliveryKey === item.deliveryKey);
                if (signature.length === 0) {
                    try {
                        const proofReq = await proofService.getProof(item.deliveryKey);
                        const proof = proofReq.data;
                        
                        signatures.push({
                            isSuccess: true,
                            deliveryKey: item.deliveryKey,
                            data: proof
                        });

                        if (item.callback) {
                            item.callback({
                                isSuccess: true,
                                data: proof
                            });
                        }
                    } catch (_) {
                        signatures.push({
                            isSuccess: false,
                            deliveryKey: item.deliveryKey,
                        });
                        item.callback({
                            isSuccess: false
                        });
                    }

                    //request delay
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    });
                }
                else {
                    item.callback({
                        isSuccess: true,
                        data: signature[0].data
                    });
                }
            }
            signatureCallback(signatures);
        } while (queue.length > 0);
        isInit = false;
    }
}