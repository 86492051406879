import { axiosICPLBEInstance } from "../../../utils/axios_config";

export default function UserService() {

    this.authenticate = async (reqBody, signal) => {
        return axiosICPLBEInstance.post('login', reqBody, { signal: signal });
    }

    this.refreshToken = async () => {
        return axiosICPLBEInstance.get('refreshToken');
    }

    this.revokeToken = async () => {
        return axiosICPLBEInstance.post('revokeToken');
    }
}