import axios from "axios";
import AthenaSettings, {AthenaSettingsKeys} from "./athena_settings";

const baseURLS = {
    dev: 'http://127.0.0.1:8000/api/',
    devIcplBE: 'https://icplapi.chooseathena.com/api/',
    prodIntegration: 'https://findplus.w-locate.com:8443/integration/',
    prodKHScheduler: 'https://findplus.w-locate.com/Kimhock/Scheduler/',
    // prodIcplBE: 'http://127.0.0.1/backend/public/api/', //'https://icplapi.chooseathena.com/api/',
    prodIcplBE: 'https://api.crp.w-locate.com/api/',
}

export const baseURL = baseURLS.prodIntegration;
export const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        config.headers.Token = account.api_key;
    }
    return config;
});

export const axiosICPLBEInstance = axios.create({
    baseURL: baseURLS.prodIcplBE
});

axiosICPLBEInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        const token = account.token;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const axiosKHSchedulerInstance = axios.create({
    baseURL: baseURLS.prodKHScheduler
});

axiosKHSchedulerInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        config.headers.Token = account.api_key;
    }
    return config;
});