import { axiosICPLBEInstance } from "../../utils/axios_config";
import EDeliveryOrderService from "./controllers/e_delivery_order";
import EPDFService from "./controllers/pdf";
import UserService from "./controllers/user";
import VehicleService from "./controllers/vehicle";
import CustomerService from "./controllers/customer";
import BatchingPlantService from "./controllers/batching_plant";
import ProjectSiteService from "./controllers/project_site";

function ICPLBEService() {}

ICPLBEService.getEDeliveryOrderService = () => new EDeliveryOrderService();
ICPLBEService.getPDFService = () => new EPDFService();
ICPLBEService.getUserService = () => new UserService();
ICPLBEService.getVehicleService = () => new VehicleService();
ICPLBEService.getCustomerService = () => new CustomerService();
ICPLBEService.getBatchingPlantService = () => new BatchingPlantService();
ICPLBEService.getProjectSiteService = () => new ProjectSiteService();
ICPLBEService.baseUrl = axiosICPLBEInstance.defaults.baseURL;

export default ICPLBEService;
